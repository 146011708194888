
async function getAddress() {
    const get_address = await window.mpurse.getAddress();
    console.log(get_address);
    const mpchainParams = {address: get_address};
    const balance = await window.mpurse.mpchain('balances', mpchainParams);
    console.log(balance);

    return get_address;
}

async function getBalance() {
    const get_address = await window.mpurse.getAddress();
    console.log(get_address);
    const mpchainParams = {address: get_address};
    const balance = await window.mpurse.mpchain('balances', mpchainParams);
    console.log(balance);

    return balance;
}
